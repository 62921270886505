import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'

import { loadPlatforms } from '../Slice/PlatformSlice'
import { setGamePlatform } from '../../GamePlatform/Slice/GamePlatformSlice'

import DuplicatePlatformModal from '../../Serie/Views/DuplicatePlatformModal'

export default function SearchPlatform ({ name = 'platform', value, onChange, required = true, size = 7 }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [platform, setPlatform] = useState(null)
  const [openDuplicatePlatformModal, setOpenDuplicatePlatformModal] = useState(false)

  const { form: game, isLoading } = useSelector((state) => state.game)
  const { form: gamePlatform } = useSelector((state) => state.gameplatform)
  const { list } = useSelector((state) => state.platform)

  const platformIds = game?.platforms.map((gamePlatform) => gamePlatform.platformId)
  const platformsFiltered = list?.filter((platform) => !platformIds.includes(platform.id))

  useEffect(() => {
    dispatch(loadPlatforms({ limit: 100 }))
  }, [])

  const onDropdownChange = (e, data) => {
    if (game?.platforms.length > 0) {
      setOpenDuplicatePlatformModal(true)
    }

    if (typeof onChange === 'function') {
      onChange(e, data)
    }
  }

  const onCancel = () => {
    setOpenDuplicatePlatformModal(false)
  }

  const onDuplicate = () => {
    setOpenDuplicatePlatformModal(false)
    const platformFound = game.platforms.find(platformInfo => platformInfo.platformId === platform)
    dispatch(setGamePlatform({
      ...platformFound,
      platformId: gamePlatform.platformId
    }))
  }

  return (
    <>
      {
        !platformsFiltered.length
          ? <p style={{ color: 'red', fontSize: 16 }}>* Ya se han añadido todas las plataformas.</p>
          : <Form.Dropdown
            fluid
            label={t('platform.title')}
            loading={isLoading}
            name={name}
            options={platformsFiltered.map((element) => ({
              key: element.id,
              text: element.name,
              value: element.id,
              description: element.name
            }))}
            placeholder={t('platform.search')}
            search
            required={required}
            selection
            value={value}
            width={size}
            onChange={onDropdownChange}
          />
      }
      <DuplicatePlatformModal
        open={openDuplicatePlatformModal}
        platform={platform}
        onCancel={onCancel}
        onDuplicate={onDuplicate}
        setPlatform={setPlatform}
      />
    </>
  )
}
